import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/shift.module.scss"
import "../assets/styles/sh1ft.scss"
import { IMG } from "../const"
import ContactForm from "../components/contact-form"
import {
  PERSONAS,
  COLUMNS,
  PROGRESS_CONTENT,
  TECHNOLOGIES,
} from "../data/sh1ftPage"

const Shift = () => {
  return (
    <Layout pageTitle="Sh1ft">
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={cx(styles.shiftPage, "glossy")}>
            <section className={styles.topBlock}>
              <div>
                <div className={styles.textBlock}>
                  <img
                    src={`${IMG.SH1FT}/logo-sh1ft.svg`}
                    alt="Sh1ft"
                    width="150"
                    height="43"
                    className={styles.logo}
                  />
                  <h4 className={styles.activeGreen}>UX/UI CASE STUDY</h4>
                  <h1>Group fitness class application</h1>
                  <p>
                    Sh1ft has no restrictions, and instructors can teach WHEN,
                    HOW and WHERE they want in different locations.
                  </p>
                  <p className={styles.small}>
                    In Sh1ft trainers have total control over what they need and
                    use. There is a possibility to teach live anywhere, teach
                    virtually, and create their&nbsp;mashups.
                  </p>
                  <div className={styles.linksWrapper}>
                    <a
                      href="https://apps.apple.com/gb/app/sh1ft/id1197308499"
                      title="Download on App Store"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <figure>
                        <picture>
                          <source
                            media="(max-width: 433px)"
                            srcSet={`
                              ${IMG.SH1FT}/appstore-160.avif,
                              ${IMG.SH1FT}/appstore-320.avif 2x,
                              ${IMG.SH1FT}/appstore-480.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(max-width: 433px)"
                            srcSet={`
                              ${IMG.SH1FT}/appstore-160.webp,
                              ${IMG.SH1FT}/appstore-320.webp 2x,
                              ${IMG.SH1FT}/appstore-480.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(max-width: 433px)"
                            srcSet={`
                              ${IMG.SH1FT}/appstore-160.png,
                              ${IMG.SH1FT}/appstore-320.png 2x,
                              ${IMG.SH1FT}/appstore-480.png 3x`}
                          />
                          <source
                            media="(min-width: 434px)"
                            srcSet={`
                              ${IMG.SH1FT}/appstore-193.avif,
                              ${IMG.SH1FT}/appstore-386.avif 2x,
                              ${IMG.SH1FT}/appstore-579.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(min-width: 434px)"
                            srcSet={`
                              ${IMG.SH1FT}/appstore-193.webp,
                              ${IMG.SH1FT}/appstore-386.webp 2x,
                              ${IMG.SH1FT}/appstore-579.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(min-width: 434px)"
                            srcSet={`
                              ${IMG.SH1FT}/appstore-193.png,
                              ${IMG.SH1FT}/appstore-386.png 2x,
                              ${IMG.SH1FT}/appstore-579.png 3x`}
                          />
                          <img
                            alt="Download on the App Store"
                            src={`${IMG.SH1FT}/appstore-193.png`}
                            width="193"
                            height="58"
                            loading="lazy"
                          />
                        </picture>
                        <figcaption>Download on the App Store</figcaption>
                      </figure>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.netsells.sh1ft"
                      title="Download on Google play"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <figure>
                        <picture>
                          <source
                            media="(max-width: 433px)"
                            srcSet={`
                              ${IMG.SH1FT}/google-play-160.avif,
                              ${IMG.SH1FT}/google-play-320.avif 2x,
                              ${IMG.SH1FT}/google-play-480.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(max-width: 433px)"
                            srcSet={`
                              ${IMG.SH1FT}/google-play-160.webp,
                              ${IMG.SH1FT}/google-play-320.webp 2x,
                              ${IMG.SH1FT}/google-play-480.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(max-width: 433px)"
                            srcSet={`
                              ${IMG.SH1FT}/google-play-160.png,
                              ${IMG.SH1FT}/google-play-320.png 2x,
                              ${IMG.SH1FT}/google-play-480.png 3x`}
                          />
                          <source
                            media="(min-width: 434px)"
                            srcSet={`
                              ${IMG.SH1FT}/google-play-193.avif,
                              ${IMG.SH1FT}/google-play-386.avif 2x,
                              ${IMG.SH1FT}/google-play-579.avif 3x`}
                            type="image/avif"
                          />
                          <source
                            media="(min-width: 434px)"
                            srcSet={`
                              ${IMG.SH1FT}/google-play-193.webp,
                              ${IMG.SH1FT}/google-play-386.webp 2x,
                              ${IMG.SH1FT}/google-play-579.webp 3x`}
                            type="image/webp"
                          />
                          <source
                            media="(min-width: 434px)"
                            srcSet={`
                              ${IMG.SH1FT}/google-play-193.png,
                              ${IMG.SH1FT}/google-play-386.png 2x,
                              ${IMG.SH1FT}/google-play-579.png 3x`}
                          />
                          <img
                            alt="Download on Google Play"
                            src={`${IMG.SH1FT}/google-play-193.png`}
                            width="193"
                            height="58"
                            loading="lazy"
                          />
                        </picture>
                        <figcaption>Download on Google Play</figcaption>
                      </figure>
                    </a>
                  </div>
                </div>
                <div className={styles.textImage}>
                  <picture>
                    <source
                      media="(min-width: 1024px)"
                      srcSet={`
                        ${IMG.SH1FT}/first-840.avif,
                        ${IMG.SH1FT}/first-1680.avif 2x,
                        ${IMG.SH1FT}/first-2520.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 1024px)"
                      srcSet={`
                        ${IMG.SH1FT}/first-840.webp,
                        ${IMG.SH1FT}/first-1680.webp 2x,
                        ${IMG.SH1FT}/first-2520.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 1024px)"
                      srcSet={`
                        ${IMG.SH1FT}/first-840.png,
                        ${IMG.SH1FT}/first-1680.png 2x,
                        ${IMG.SH1FT}/first-2520.png 3x`}
                    />

                    <source
                      media="(min-width: 1440px)"
                      srcSet={`
                        ${IMG.SH1FT}/first-1094.avif,
                        ${IMG.SH1FT}/first-2188.avif 2x,
                        ${IMG.SH1FT}/first-3282.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 1440px)"
                      srcSet={`
                        ${IMG.SH1FT}/first-1094.webp,
                        ${IMG.SH1FT}/first-2188.webp 2x,
                        ${IMG.SH1FT}/first-3282.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 1440px)"
                      srcSet={`
                        ${IMG.SH1FT}/first-1094.png,
                        ${IMG.SH1FT}/first-2188.png 2x,
                        ${IMG.SH1FT}/first-3282.png 3x`}
                    />
                    <img
                      alt="Phone screens"
                      src={`${IMG.SH1FT}/first-840.png`}
                      width="840"
                      height="1260"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </section>

            <section className={cx(styles.centerAlign, styles.challenges)}>
              <div>
                <h4 className={styles.activeGreen}>Product challenge</h4>
                <h2>Challenges</h2>
                <p>
                  <strong>
                    “So we realized fully integrated mobile app and website
                    which are tracking whole-body parameters, burned out
                    activities, digital integrations and components to provide
                    needed activities.”
                  </strong>
                </p>
                <p>
                  Group fitness and digital health have changed forever. And
                  the&nbsp;main&nbsp;challenges in training for average users
                  are:
                </p>
                <ul className={styles.colorTextWrapper}>
                  <li>
                    Increasing the effectiveness of the
                    current&nbsp;fitness&nbsp;and sport performance
                  </li>
                  <li>Full control over the sports program</li>
                  <li>
                    <img
                      src={`${IMG.SH1FT}/lightning.svg`}
                      alt="Lightning icon"
                      width="12"
                      height="18"
                      loading="lazy"
                    />
                    Teaching fitness online
                  </li>
                </ul>
              </div>
            </section>

            <section className={styles.process}>
              <div>
                <h4 className={styles.activeGreen}>Project process</h4>
                <div className={styles.twoColsWrapper}>
                  <h2>Process and activities&nbsp;we realized</h2>
                  <p>
                    We worked with this product in a couple of&nbsp;iterations
                    starting in 2019, including product design, admin platform
                    and mobile&nbsp;application.
                  </p>
                </div>
                <div className={styles.processStepsWrapper}>
                  <div>
                    <div className={styles.processStepsContent}>
                      {PROGRESS_CONTENT.map((item, index) => (
                        <div
                          key={index}
                          className={`col-item ${
                            item.width
                              ? `${item.width} ${item.bg_color}`
                              : `${item.bg_color}`
                          }`}
                        >
                          {item.text}
                        </div>
                      ))}
                    </div>
                    <div className={styles.processStepsGrid}>
                      {COLUMNS.map(item => (
                        <div key={item}>
                          <div className={styles.order}>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className={cx(styles.centerAlign, styles.sitemap)}>
              <div>
                <h4 className={styles.activeGreen}>Project structure</h4>
                <h2>Sitemap diagram</h2>
                <p>
                  It reflects a product development implementation process
                  with&nbsp;structure&nbsp;and&nbsp;components.
                </p>
                <div className={styles.scheme}>
                  <picture>
                    <source
                      media="(max-width: 480px)"
                      srcSet={`
                        ${IMG.SH1FT}/scheme-450.avif,
                        ${IMG.SH1FT}/scheme-900.avif 2x,
                        ${IMG.SH1FT}/scheme-1350.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 480px)"
                      srcSet={`
                        ${IMG.SH1FT}/scheme-450.webp,
                        ${IMG.SH1FT}/scheme-900.webp 2x,
                        ${IMG.SH1FT}/scheme-1350.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 480px)"
                      srcSet={`
                        ${IMG.SH1FT}/scheme-450.png,
                        ${IMG.SH1FT}/scheme-900.png 2x,
                        ${IMG.SH1FT}/scheme-1350.png 3x`}
                    />

                    <source
                      media="(min-width: 481px)"
                      srcSet={`
                        ${IMG.SH1FT}/scheme-1070.avif,
                        ${IMG.SH1FT}/scheme-2140.avif 2x,
                        ${IMG.SH1FT}/scheme-3210.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 481px)"
                      srcSet={`
                        ${IMG.SH1FT}/scheme-1070.webp,
                        ${IMG.SH1FT}/scheme-2140.webp 2x,
                        ${IMG.SH1FT}/scheme-3210.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 481px)"
                      srcSet={`
                        ${IMG.SH1FT}/scheme-1070.png,
                        ${IMG.SH1FT}/scheme-2140.png 2x,
                        ${IMG.SH1FT}/scheme-3210.png 3x`}
                    />
                    <img
                      alt="Scheme"
                      src={`${IMG.SH1FT}/scheme-450.png`}
                      width="450"
                      height="239"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </section>

            <section className={cx(styles.centerAlign, styles.research)}>
              <div>
                <div className={styles.identifier}>
                  <h4 className={styles.activeGreen}>Market research</h4>
                  <h2 className={styles.large}>
                    UX Research identified Personas
                  </h2>
                  <p>
                    At the beginning of product realization, we led UX
                    research,&nbsp;identified&nbsp;buying personas, conducted
                    interviews and&nbsp;started forming the design.
                  </p>
                </div>
                <div className={styles.threeColsWrapper}>
                  {PERSONAS.map(persona => (
                    <article key={persona.name}>
                      <div className={styles.squarePicWrapper}>
                        {persona.image && (
                          <picture>
                            <source
                              srcSet={`
                                ${IMG.SH1FT}/${persona.image}-368.avif,
                                ${IMG.SH1FT}/${persona.image}-736.avif 2x,
                                ${IMG.SH1FT}/${persona.image}-1104.avif 3x`}
                              type="image/avif"
                            />
                            <source
                              srcSet={`
                                ${IMG.SH1FT}/${persona.image}-368.webp,
                                ${IMG.SH1FT}/${persona.image}-736.webp 2x,
                                ${IMG.SH1FT}/${persona.image}-1104.webp 3x`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`
                                ${IMG.SH1FT}/${persona.image}-368.jpg,
                                ${IMG.SH1FT}/${persona.image}-736.jpg 2x,
                                ${IMG.SH1FT}/${persona.image}-1104.jpg 3x`}
                            />
                            <img
                              alt={persona.identifier}
                              src={`${IMG.SH1FT}/${persona.image}-368.jpg`}
                              width="368"
                              height="368"
                              loading="lazy"
                            />
                          </picture>
                        )}
                      </div>
                      <div className={styles.personaInfo}>
                        <dl>
                          <dt>{persona.identifier}</dt>
                          <dd>{persona.name}</dd>
                        </dl>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </section>

            <section className={styles.fidelity}>
              <div>
                <h4 className={styles.activeGreen}>Project design</h4>
                <div className={styles.twoColsWrapper}>
                  <h2 className={styles.large}>Low fidelity mockups</h2>
                  <p>
                    The next stage of the product realization process was to
                    develop low fidelity mockups with product structure.
                  </p>
                </div>
                <div className={styles.mockup}>
                  <picture>
                    <source
                      media="(max-width: 480px)"
                      srcSet={`
                        ${IMG.SH1FT}/mockup-489.avif,
                        ${IMG.SH1FT}/mockup-978.avif 2x,
                        ${IMG.SH1FT}/mockup-1467.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 480px)"
                      srcSet={`
                        ${IMG.SH1FT}/mockup-489.webp,
                        ${IMG.SH1FT}/mockup-978.webp 2x,
                        ${IMG.SH1FT}/mockup-1467.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 480px)"
                      srcSet={`
                        ${IMG.SH1FT}/mockup-489.png,
                        ${IMG.SH1FT}/mockup-978.png 2x,
                        ${IMG.SH1FT}/mockup-1467.png 3x`}
                    />

                    <source
                      media="(min-width: 481px)"
                      srcSet={`
                        ${IMG.SH1FT}/mockup-1240.avif,
                        ${IMG.SH1FT}/mockup-2480.avif 2x,
                        ${IMG.SH1FT}/mockup-3720.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 481px)"
                      srcSet={`
                        ${IMG.SH1FT}/mockup-1240.webp,
                        ${IMG.SH1FT}/mockup-2480.webp 2x,
                        ${IMG.SH1FT}/mockup-3720.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 481px)"
                      srcSet={`
                        ${IMG.SH1FT}/mockup-1240.png,
                        ${IMG.SH1FT}/mockup-2480.png 2x,
                        ${IMG.SH1FT}/mockup-3720.png 3x`}
                    />
                    <img
                      alt="Mockup"
                      src={`${IMG.SH1FT}/mockup-489.png`}
                      width="489"
                      height="289"
                      loading="lazy"
                    />
                  </picture>
                </div>

                <div className={styles.twoColsWrapper}>
                  <div>
                    <h4 className={styles.activeGreen}>Project design</h4>
                    <h2 className={styles.large}>High fidelity mockups</h2>
                    <p>
                      Based on low fidelity mockups we <br />
                      realized high fidelity and complete design of <br />
                      the product.
                    </p>
                  </div>
                  <div>
                    <picture>
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
                          ${IMG.SH1FT}/img-365.avif,
                          ${IMG.SH1FT}/img-730.avif 2x,
                          ${IMG.SH1FT}/img-1096.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
                          ${IMG.SH1FT}/img-365.webp,
                          ${IMG.SH1FT}/img-730.webp 2x,
                          ${IMG.SH1FT}/img-1096.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
                          ${IMG.SH1FT}/img-365.png,
                          ${IMG.SH1FT}/img-730.png 2x,
                          ${IMG.SH1FT}/img-1096.png 3x`}
                      />

                      <source
                        media="(min-width: 481px)"
                        srcSet={`
                          ${IMG.SH1FT}/img-708.avif,
                          ${IMG.SH1FT}/img-1416.avif 2x,
                          ${IMG.SH1FT}/img-2124.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
                          ${IMG.SH1FT}/img-708.webp,
                          ${IMG.SH1FT}/img-1416.webp 2x,
                          ${IMG.SH1FT}/img-2124.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
                          ${IMG.SH1FT}/img-708.png,
                          ${IMG.SH1FT}/img-1416.png 2x,
                          ${IMG.SH1FT}/img-2124.png 3x`}
                      />
                      <img
                        alt="Mockup"
                        src={`${IMG.SH1FT}/img-365.png`}
                        width="365"
                        height="379"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>

            <section className={cx(styles.centerAlign, styles.products)}>
              <div>
                <h4 className={styles.activeGreen}>Product components</h4>
                <h2 className={styles.large}>3 SH1FT Products</h2>

                <div className={styles.container}>
                  <div className={cx(styles.twoColsWrapper, styles.swapOrder)}>
                    <div className={styles.twoImgWrapper}>
                      <picture>
                        <source
                          srcSet={`
                            ${IMG.SH1FT}/woman-328.avif,
                            ${IMG.SH1FT}/woman-656.avif 2x,
                            ${IMG.SH1FT}/woman-984.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`
                            ${IMG.SH1FT}/woman-328.webp,
                            ${IMG.SH1FT}/woman-656.webp 2x,
                            ${IMG.SH1FT}/woman-984.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`
                            ${IMG.SH1FT}/woman-328.jpg,
                            ${IMG.SH1FT}/woman-656.jpg 2x,
                            ${IMG.SH1FT}/woman-984.jpg 3x`}
                        />
                        <img
                          className={styles.first}
                          alt="Smiling woman"
                          src={`${IMG.SH1FT}/woman-328.jpg`}
                          width="328"
                          height="540"
                          loading="lazy"
                        />
                      </picture>
                      <picture>
                        <source
                          srcSet={`
                            ${IMG.SH1FT}/man-328.avif,
                            ${IMG.SH1FT}/man-656.avif 2x,
                            ${IMG.SH1FT}/man-984.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`
                            ${IMG.SH1FT}/man-328.webp,
                            ${IMG.SH1FT}/man-656.webp 2x,
                            ${IMG.SH1FT}/man-984.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`
                            ${IMG.SH1FT}/man-328.jpg,
                            ${IMG.SH1FT}/man-656.jpg 2x,
                            ${IMG.SH1FT}/man-984.jpg 3x`}
                        />
                        <img
                          className={styles.second}
                          alt="Man in gym"
                          src={`${IMG.SH1FT}/man-328.jpg`}
                          width="328"
                          height="537"
                          loading="lazy"
                        />
                      </picture>

                      <div>
                        <ul>
                          <li>Five Group</li>
                          <li>Fitness</li>
                          <li>Formats, ONE</li>
                          <li>Low Cost</li>
                          <li>Membership.</li>
                        </ul>
                      </div>
                    </div>
                    <div className={styles.textLeft}>
                      <h3>SH1FT Coach Portal at SH1FTfitness.com</h3>
                      <p>
                        Complete high-quality virtual workouts for the members
                        which consist of such components as:
                      </p>
                      <ul className={styles.textLeft__ul}>
                        <li>Mobility & stability training,</li>
                        <li>
                          High intensity functional training using the step,
                        </li>
                        <li>Low impact cardio on any bike.</li>
                      </ul>
                    </div>
                  </div>

                  <div className={styles.twoColsWrapper}>
                    <div className={styles.imgLeft}>
                      <picture>
                        <source
                          media="(max-width: 480px)"
                          srcSet={`
                            ${IMG.SH1FT}/admin-326.avif,
                            ${IMG.SH1FT}/admin-652.avif 2x,
                            ${IMG.SH1FT}/admin-978.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 480px)"
                          srcSet={`
                            ${IMG.SH1FT}/admin-326.webp,
                            ${IMG.SH1FT}/admin-652.webp 2x,
                            ${IMG.SH1FT}/admin-978.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 480px)"
                          srcSet={`
                            ${IMG.SH1FT}/admin-326.png,
                            ${IMG.SH1FT}/admin-652.png 2x,
                            ${IMG.SH1FT}/admin-978.png 3x`}
                        />
                        <source
                          media="(min-width: 481px)"
                          srcSet={`
                            ${IMG.SH1FT}/admin-538.avif,
                            ${IMG.SH1FT}/admin-1076.avif 2x,
                            ${IMG.SH1FT}/admin-1614.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 481px)"
                          srcSet={`
                            ${IMG.SH1FT}/admin-538.webp,
                            ${IMG.SH1FT}/admin-1076.webp 2x,
                            ${IMG.SH1FT}/admin-1614.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 481px)"
                          srcSet={`
                            ${IMG.SH1FT}/admin-538.png,
                            ${IMG.SH1FT}/admin-1076.png 2x,
                            ${IMG.SH1FT}/admin-1614.png 3x`}
                        />
                        <img
                          alt="Admin"
                          src={`${IMG.SH1FT}/admin-326.png`}
                          width="326"
                          height="291"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div>
                      <h3>SHIFT Admin Portal</h3>
                      <p>
                        It allows training management and building users’
                        exercise programs with the exact type of training and
                        trainer they need. The admin panel helps them configure
                        their training style and save videos with the trainer
                        precisely for them.
                      </p>
                    </div>
                  </div>

                  <div className={cx(styles.twoColsWrapper, styles.swapOrder)}>
                    <div>
                      <picture>
                        <source
                          media="(max-width: 480px)"
                          srcSet={`
                            ${IMG.SH1FT}/phones-330.avif,
                            ${IMG.SH1FT}/phones-660.avif 2x,
                            ${IMG.SH1FT}/phones-990.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(max-width: 480px)"
                          srcSet={`
                            ${IMG.SH1FT}/phones-330.webp,
                            ${IMG.SH1FT}/phones-660.webp 2x,
                            ${IMG.SH1FT}/phones-990.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(max-width: 480px)"
                          srcSet={`
                            ${IMG.SH1FT}/phones-330.png,
                            ${IMG.SH1FT}/phones-660.png 2x,
                            ${IMG.SH1FT}/phones-990.png 3x`}
                        />
                        <source
                          media="(min-width: 481px)"
                          srcSet={`
                            ${IMG.SH1FT}/phones-608.avif,
                            ${IMG.SH1FT}/phones-1216.avif 2x,
                            ${IMG.SH1FT}/phones-1824.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          media="(min-width: 481px)"
                          srcSet={`
                            ${IMG.SH1FT}/phones-608.webp,
                            ${IMG.SH1FT}/phones-1216.webp 2x,
                            ${IMG.SH1FT}/phones-1824.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          media="(min-width: 481px)"
                          srcSet={`
                            ${IMG.SH1FT}/phones-608.png,
                            ${IMG.SH1FT}/phones-1216.png 2x,
                            ${IMG.SH1FT}/phones-1824.png 3x`}
                        />
                        <img
                          alt="Phones"
                          src={`${IMG.SH1FT}/phones-330.png`}
                          width="330"
                          height="443"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div className={styles.textLeft}>
                      <h3>SH1FT Mobile App</h3>
                      <p>
                        Easy on-the-go access to different training sets from a
                        mobile phone anywhere. There is a possibility to plan
                        training with a timer, exercises, and schemes. Users can
                        download different types of activities on their phones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className={cx(styles.centerAlign, styles.technologiesSection)}
            >
              <div>
                <h4 className={styles.activeGreen}>Technologies used</h4>
                <h2 className={styles.large}>Technologies</h2>
                <p>Sh1ft was realized using such technologies as:</p>
                <ul className={styles.technologies}>
                  {TECHNOLOGIES.map(item => (
                    <li key={item.name}>
                      <div className={styles.img}>
                        {item.icon && (
                          <img
                            src={`${IMG.ICONS}/${item.icon}`}
                            alt={item.name}
                            width="120"
                            height="120"
                            loading="lazy"
                          />
                        )}
                      </div>
                      <span>{item.name}</span>
                    </li>
                  ))}
                </ul>
                <div className={styles.feedback}>
                  <blockquote>
                    Our requests were clearly understood and implemented
                    correctly. Their excellent communication and consistent
                    updates made our lives easier.
                  </blockquote>
                  <figure>
                    <picture>
                      <source
                        srcSet={`${IMG.TESTIMONIALS}/Samantha-Crossman.avif,  ${IMG.TESTIMONIALS}/Samantha-Crossman@2x.avif 2x, ${IMG.TESTIMONIALS}/Samantha-Crossman@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.TESTIMONIALS}/Samantha-Crossman.webp,  ${IMG.TESTIMONIALS}/Samantha-Crossman@2x.webp 2x, ${IMG.TESTIMONIALS}/Samantha-Crossman@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`${IMG.TESTIMONIALS}/Samantha-Crossman.jpg,   ${IMG.TESTIMONIALS}/Samantha-Crossman@2x.jpg 2x,  ${IMG.TESTIMONIALS}/Samantha-Crossman@3x.jpg 3x`}
                      />
                      <img
                        src={`${IMG.TESTIMONIALS}/Samantha-Crossman.jpg`}
                        alt="Samantha Crossman"
                        width="48"
                        height="48"
                        loading="lazy"
                      />
                    </picture>
                    <figcaption itemScope itemType="https://schema.org/Person">
                      <strong itemProp="name">Samantha Crossman</strong>
                      <span itemProp="jobTitle">General Manager at Sh1ft</span>
                      <a
                        href="https://uk.linkedin.com/in/samantha-brereton-6a882540"
                        target="_blank"
                        rel="noreferrer nofollow noopener"
                      >
                        <span>Samantha Crossman in LinkedIn</span>
                      </a>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </section>
            <section>
              <div>
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default Shift
